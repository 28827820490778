<template>
  <q-btn
    @click="$emit('click')"
    dense
    flat
    round
    color="grey-7"
    icon="sym_r_close"
  />
</template>

<script>
export default {
  name: "CloseButton",
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    },
    size: {
      type: String,
      required: false,
      default: "2.5em"
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    }
  },
  emits: ["click"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
